.image-contents-container {
    display: flex;
    align-items: center;
}

.implant-subpage-images{
    border: solid 3px rgb(10, 134, 202);
    width: 100%;
    max-height: 150px;
}


/* ********* MEDIA QUERY ********** */
@media only screen and (max-width: 992px) {
    .dental-implant-page-container{
        max-width: 100%;
    }
    .implant-subpage-images{
        border: solid 3px rgb(10, 134, 202);
        width: 100%;
        max-height: 100%;
    }
}