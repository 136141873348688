/* image size CSS */
.home-sliders-image{
    max-height: 600px;
    width: auto;
}

.carousel-caption {
    top: 40%;
    transform: translate(-50%);
    left: 50%;
    right: -50%;
    bottom: inherit;
  }
  
  .carousel-caption>h2 {
    font-family: droid-serif;
    font-size: 50px;
    font-weight: 700;
  }
  
  .carousel-inner .carousel-item>img {
    -webkit-animation: zoomin 20s;
    -o-animation: zoomin 20s;
    animation: zoomin 20s;
  }
  
  @keyframes zoomin {
    from {
      transform: scale(1, 1);
    }
    to {
      transform: scale(1.2, 1.2);
    }
  }
  
  
  /* Large devices (desktops, 992px and bellow) */
  @media only screen and (max-width: 992px) {
  
    /* Copywrite section media query */
    .carousel-caption {
      top: 30%;
    }
  }
  @media only screen and (max-width: 576px) {
  
    /* Copywrite section media query */
    .carousel-caption {
      top: 10%;
    }
  }