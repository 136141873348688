
.social-media-icon{
    width: 2rem;
}

/* ********* MEDIA QUERY ********** */
@media only screen and (max-width: 992px) {
    .social-media-icons-container{
        justify-content: center;
    }
}


