.description-images{
    display: flex;
    justify-content: space-evenly;
}

/* ********* MEDIA QUERY ********** */
@media only screen and (max-width: 992px) {
    .tooth-extraction-container{
        max-width: 100%;
    }
}