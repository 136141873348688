.our-dentist-container{
    padding: 2rem;
}

.our-dentist-section-contents{
    display: flex;
    justify-content: space-evenly;
    /* flex-wrap: wrap; */
}

.left-side-doctor-contents, .right-side-doctor-contents{
    padding: 1rem;
}

.doc-images{
    max-width: 450px;
    height: auto;
    object-fit: cover;
    display: flex;
    justify-content: center;
}


/*************** media query ************/

/* Large devices (desktops, 992px and bellow) */
@media only screen and (max-width: 992px) {
    .doc-images{
       width: 100%;
    }
}

/* Medium devices (tablets, 768px and bellow) */
@media only screen and (max-width: 768px) {
    .doc-images{
        width: 100%;
    }
    .our-dentist-section-contents{
        flex-direction: column;
        align-items: center;
    }
}