.counter-container{
    padding: 2rem;
    background-color: #11629c;
}

.counter{
    font-family: 'Nunito Sans', sans-serif;
    text-align: center;
    height: 210px;
    width: 210px;
    padding: 7px 4px 0;
    margin: 0 auto;
    position: relative;
    z-index: 1;
}
.counter:before{
    content: '';
    background-color: #fff;
    height: 157px;
    width: 157px;
    border-radius: 15px;
    border: 5px solid #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1) inset;
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: -1;
}
.counter .counter-icon{
    color: #fff;
    background: linear-gradient(#F83F83,#E2056F);
    line-height: 70px;
    font-size: 35px;
    height: 70px;
    width: 70px;
    margin: 0 auto 8px;
    border-radius: 19px 0 50px;
    transform: rotate(45deg);
}
.counter .counter-icon i{ transform: rotate(-45deg); }
.counter .counter-value{
    font-size: 25px;
    font-weight: 700;
    letter-spacing: 1px;
    margin: 0 0 13px 0;
    display: block;
}
.counter h3{
    color: #fff;
    background: linear-gradient(#F83F83,#E2056F);
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: capitalize;
    padding: 10px 5px;
    margin: 0;
    border-radius: 0 0 20px 20px;
    position: relative;
}
.counter h3:before,
.counter h3:after{
    content: "";
    background: linear-gradient(to right bottom, transparent 49%,#c90460 50%);
    width: 20px;
    height: 20px;
    position: absolute;
    top: -20px;
    left: 0;
    z-index: -2;
}
.counter h3:after{
    transform: rotateY(180deg);
    left: auto;
    right: 0;
}
.counter.purple .counter-icon,
.counter.purple h3{
    background: linear-gradient(#B05FDF,#7B26DD);
}
.counter.purple h3:before,
.counter.purple h3:after{
    background: linear-gradient(to right bottom,transparent 49%,#5a07bf 50%);
}
.counter.blue .counter-icon,
.counter.blue h3{
    background: linear-gradient(#00BCF9,#027AF6);
}
.counter.blue h3:before,
.counter.blue h3:after{
    background: linear-gradient(to right bottom,transparent 49%,#0466c9 50%);
}
.counter.green .counter-icon,
.counter.green h3{
    background: linear-gradient(#aff400,#6cc425);
}
.counter.green h3:before,
.counter.green h3:after{
    background: linear-gradient(to right bottom,transparent 49%,#489e03 50%);
}
@media screen and (max-width:990px){
    .counter{ margin-bottom: 40px; }
}

