.home-contact-section{
    padding-top: 2rem;
}

.location-information {
    display: flex;
    justify-content: center;
}

.google-map{
    width: 600px;
    height: 450px;
}
.address{
    background-color: rgb(36, 97, 130);
}

/* medimum device (tablet, 992px and bellow) */
@media only screen and (max-width: 992px) {
    .location-information {
        flex-direction: column;
    }
}
/* Small devices (landscape phones, less than 768px)  */
@media only screen and (max-width: 768px) {
    .google-map{
        width: 400px;
    }
}

/* X-Small devices (portrait phones, less than 576px) */
@media only screen and (max-width: 575.98px) { 
    .google-map{
        width: 300px;
    }
 }