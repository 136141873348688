.review-parent-container{
    padding: 2rem;
    background-color: #3fb550;
}
.tour-desc{
    background-color: #3fb550 !important;
}

.image-frame{
    width: 100px;
    height: auto;
}
.tm-people{
    width: 100px;
    height: auto;  
    border-radius: 50px;
    background-color: red;
    padding: 3px;
}

.tour-desc{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
}
.link-name{
    margin-top: 10px;
    font-size: 18px;
    font-weight: bold;
}
.review-container h2{
    display: flex;
    justify-content: center;
    margin: 20px 0px;
}

/* disable the bottom dots sign in the bottom of reciew section*/
.slick-dots{
    display: none !important;
}