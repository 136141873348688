.top-container {
    background-color: #ffffff;
    height: 100px;
    /* background-image: linear-gradient(to right, #E8BC45, #E53D74, #4FACD8); */
}

.items-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

/* MEDIA QUERY */
/* Medium devices (tablets, less than 992px) */
@media only screen and (max-width: 991.98px) {
    .top-container {
        display: none;
    }
}