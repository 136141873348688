.image-contents-container {
    display: flex;
    align-items: center;
}

.rct-subpage-images {
    border: solid 3px rgb(10, 134, 202);
    height: 300px;
}

.left-side-contents {
    max-width: 75%;
    padding: 1rem;
}

.description-images{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

/* ********* MEDIA QUERY ********** */
@media only screen and (max-width: 992px) {
    .root-canal-treatment-container{
        max-width: 100%;
    }
    .smile-designing-container{
        max-width: 100%;
    }
    .image-contents-container {
        flex-direction: column;
    }

    .left-side-contents {
        max-width: 100%;
        padding: 1rem;
    }

    .right-side-image {
        display: flex;
        justify-content: space-between;
    }

    .rct-subpage-images {
        height: 100%;
        width: auto;
    }
}

@media only screen and (max-width: 523px) {
    .right-side-image {
        display: flex;
        flex-direction: column;
    }
}