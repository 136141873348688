.our-services-container{
    /* margin: 2rem auto; */
    padding: 4rem;
    background-image: url("../../../bg-images/our-service-background.png");
}

.our-service-hr{
    margin: auto auto 3rem auto;
}

.service-type-container{
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.service-box{
    padding: 2rem;
    margin: 1rem;
    color: rgb(217, 217, 217);
    text-align: center;
    background-color: rgb(17, 96, 137, 0.3);
}
.service-box ::before{
    background-color: rgb(17, 96, 137);
}
